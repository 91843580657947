import { render, staticRenderFns } from "./BackgroundColorsPanel.vue?vue&type=template&id=8e9219da&scoped=true&"
import script from "./BackgroundColorsPanel.vue?vue&type=script&lang=js&"
export * from "./BackgroundColorsPanel.vue?vue&type=script&lang=js&"
import style0 from "./BackgroundColorsPanel.vue?vue&type=style&index=0&id=8e9219da&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8e9219da",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VColorPicker } from 'vuetify/lib/components/VColorPicker';
import { VFabTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCard,VCardText,VCol,VColorPicker,VFabTransition,VIcon,VImg,VRow})
