<template>
  <div>
    <div class="default__backgrounds d-flex flex-wrap mb-2">
      <div
        v-for="(style,index) in defaultBackgrounds"
        :key="index"
        class="box"
        :style="style"
        @click="applyDefaultBackground(style);colorPicker=false"
      ></div>

      <div class="box">
        <v-icon
          class="w-full"
          @click="colorPicker = !colorPicker"
        >
          {{ icons.mdiPlus }}
        </v-icon>
      </div>
    </div>

    <div
      v-if="colorPicker"
      class="d-flex  justify-center"
    >
      <v-color-picker
        v-model="customColor"
        dot-size="20"
        swatches-max-height="200"
        hide-inputs
      ></v-color-picker>
    </div>

    <v-row
      dense
      class="mb-4 mt-4"
    >
      <v-col
        cols="12"
      >
        My Backgrounds
      </v-col>
      <v-col
        v-for="background in myBackgrounds"
        :key="background.id"
        :cols="4"
      >
        <v-card
          class="pointer"
        >
          <v-img
            :src="background.background_path"
            @click="applyMyBackground(background)"
          ></v-img>

          <v-card-text style=" position: relative ">
            <v-fab-transition>
              <v-btn
                color="red p-0"
                absolute
                top
                right
                small
                fab
                @click="deleteBackground(background.id)"
              >
                <v-icon>{{ icons.mdiDeleteCircle }}</v-icon>
              </v-btn>
            </v-fab-transition>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <input
      ref="refInputFile"
      type="file"
      accept=".jpeg,.png,.jpg,GIF"
      :hidden="true"
      @input="uploadBackground($event)"
    />
    <v-btn
      small
      elevation="5"
      class="mr-2"
      primary
      icon
      @click="$refs.refInputFile.click()"
    >
      <v-icon>{{ icons.mdiPlus }}</v-icon>
    </v-btn>
    New Background
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { mdiPlus, mdiDeleteCircle } from '@mdi/js'

export default {
  data() {
    return {
      colorPicker: true,
      customColor: null,
      icons: {
        mdiPlus,
        mdiDeleteCircle,
      },
    }
  },

  computed: {
    ...mapState({
      myBackgrounds: state => state.social.myBackgrounds,
      backgroundDialg: state => state.social.backgroundDialg,
      defaultBackgrounds: state => state.social.defaultBackgrounds,
    }),
  },

  watch: {
    customColor(val) {
      this.applyDefaultBackground(`background:${val.hex}`)
    },
  },

  methods: {
    ...mapActions('social', [
      'backgroundDialgToggle',
      'deleteBackground',
      'uploadBackground',
      'applyDefaultBackground',
      'applyMyBackground',
    ]),
  },
}
</script>

<style scoped lang="scss">
.default__backgrounds {
  .box {
    cursor: pointer;
    border-radius: 50%;
    padding: 1px;
    margin: 3px 4px;
    width: 50px;
    height: 50px;
    border: 1px solid grey;
    display: flex;
    &:hover {
      border: 2px solid black;
    }
  }
}
</style>
