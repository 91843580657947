<template>
  <div class="app__sidebar">
    <!-- card settings -->
    <div class="section ">
      <label>Card settings</label>
      <div class="card__settings">
        <div class="d-flex flex-column align-center">
          <div
            class="icon"
            @click="toggleCardTheme()"
          >
            <v-icon
              v-if="card.theme == 'light'"
              class="rotate icon"
            >
              {{ icons.mdiMoonWaningCrescent }}
            </v-icon>
            <v-icon
              v-else
              class="icon"
            >
              {{ icons.mdiWhiteBalanceSunny }}
            </v-icon>
          </div>
          <span>{{ card.theme == 'light' ? 'Dark' : 'Light' }} </span>
        </div>
      </div>
    </div>

    <!-- post size section -->
    <div class="section ">
      <label>Post size</label>
      <div class="card__size">
        <div
          v-for="(size,index) in sizes"
          :key="index"
          :class="`size__box  ${card.selectedSize.height === size.height ? 'active' : ''}`"
          @click="selectSize(size)"
        >
          <div :class="`box ${size.class}`"></div>
          {{ size.title }}
        </div>
      </div>
    </div>

    <v-expansion-panels
      v-model="panel"
      class="pa-1"
    >
      <!-- themes accordin -->
      <v-expansion-panel>
        <v-expansion-panel-header>
          Themes (2)
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <ThemePanel :switch-theme="switchTheme" />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- backgrounds -->
      <v-expansion-panel>
        <v-expansion-panel-header>
          Color backgrounds
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <BackgroundColors />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- font colors -->
      <v-expansion-panel>
        <v-expansion-panel-header>
          Text Colors
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <TextColorPanel />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>
          Fonts
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <fonts-panel />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <!-- colors accordin -->
    <!-- fonts accordin -->
  </div>
</template>

<script>
import BackgroundColors from '../../twitter-images/sidebar/BackgroundColorsPanel.vue'
import FontsPanel from '../../twitter-images/sidebar/FontsPanel.vue'
import TextColorPanel from '../../twitter-images/sidebar/TextColorPanel.vue'
import ThemePanel from './ThemePanel.vue'

export default {
  components: {
    BackgroundColors,
    FontsPanel,
    ThemePanel,
    TextColorPanel,
  },
  props: ['icons', 'switchTheme', 'toggleCardTheme', 'isActionDisabled', 'card', 'sizes', 'selectSize'],

  data() {
    return {
      panel: [0, 1],
    }
  },
  methods: {
    toggleResponse() {
      this.card.response = !this.card.response
      this.$emit('update:card', this.card)
    },
    toggleDate() {
      this.card.showDate = !this.card.showDate
      this.$emit('update:card', this.card)
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/tools-sidebar.scss';
</style>
