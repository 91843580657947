<template>
  <div>
    <div class="default__backgrounds d-flex flex-wrap mb-2">
      <div
        v-for="(val,index) in colorOptions"
        :key="index"
        class="box"
        :style="`background:${val}`"
        @click="setTextColor(val)"
      ></div>

      <div class="box">
        <v-icon
          class="w-full"
          @click="colorPicker = !colorPicker"
        >
          {{ icons.mdiPlus }}
        </v-icon>
      </div>
    </div>

    <div
      v-if="colorPicker"
      class="d-flex  justify-center"
    >
      <v-color-picker
        v-model="color"
        dot-size="20"
        swatches-max-height="200"
        hide-inputs
      ></v-color-picker>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { mdiPlus } from '@mdi/js'

export default {
  data() {
    return {
      color: '#000000',
      colorPicker: false,
      colorOptions: ['#000000', '#ffffff', '#ffe700', '#3aa8e4', '#c61044', '#9fc54d'],
      icons: { mdiPlus },
    }
  },

  computed: {
    ...mapState({
      textColor: state => state.social.textColor,
    }),
  },

  watch: {
    color(val) {
      this.setTextColor(val)
    },
  },

  methods: {
    ...mapActions('social', ['setTextColor']),
  },
}
</script>

<style scoped lang="scss">
.default__backgrounds {
  .box {
    cursor: pointer;
    border-radius: 50%;
    padding: 4px;
    margin: 3px 4px;
    width: 50px;
    height: 50px;
    border: 1px solid grey;
    display: flex;

    &:hover {
      border: 2px solid black;
    }
  }
}
</style>
