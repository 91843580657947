<template>
  <div
    ref="theme"
    :class="`theme__${card.theme} notepaper`"
  >
    <figure class="quote">
      <div class="hide__overflow pa-2">
        <blockquote
          ref="content"
          class="curly-quotes content"
          contenteditable
          :style="styles"
          @keyup="fitContent"
        >
          {{ quote.text }}
        </blockquote>
      </div>

      <figcaption
        class="quote-by"
        :style="styles"

        contenteditable
      >
        — {{ quote.user.name }}
      </figcaption>
    </figure>
  </div>
</template>

<script>
import QuoteTheme from '@/views/social/mixins/QuoteTheme'

export default {
  mixins: [QuoteTheme],

  props: {
    fitContent: {
      required: true,
      type: Function,
    },
    quote: {
      type: Object,
      required: true,
    },
    card: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      heights: [
        {
          on: 630,
          allowed: 480,
          fontSize: 16,
          minFontSize: 12,
        },
        {
          on: 1080,
          allowed: 880,
          fontSize: 24,
          minFontSize: 14,
        },
        {
          on: 1920,
          allowed: 1720,
          fontSize: 36,
          minFontSize: 14,
        },
      ],
    }
  },
  mounted() {
    this.fitContent()
  },
}
</script>

<style scoped lang="scss">
@import '@/styles/tweet-task.scss';
.theme__dark {
  background: #28243d;
  color: white;
}
.theme__light {
  background-color: #f2f6c1;
  color: #6a5f49;
}
.notepaper {
  position: relative;
  margin: 30px auto;
  padding: 29px 20px 20px 45px;
  width: 80%;
  line-height: 30px;
  text-shadow: 0 1px 1px white;
  background-image: -webkit-radial-gradient(center, cover, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.1) 90%),
    -webkit-repeating-linear-gradient(top, transparent 0%, transparent 29px, rgba(239, 207, 173, 0.7) 29px, rgba(
            239,
            207,
            173,
            0.7
          )
          30px);
  background-image: -moz-radial-gradient(center, cover, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.1) 90%),
    -moz-repeating-linear-gradient(top, transparent 0%, transparent 29px, rgba(239, 207, 173, 0.7) 29px, rgba(
            239,
            207,
            173,
            0.7
          )
          30px);
  background-image: -o-radial-gradient(center, cover, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.1) 90%),
    -o-repeating-linear-gradient(top, transparent 0%, transparent 29px, rgba(239, 207, 173, 0.7) 29px, rgba(
            239,
            207,
            173,
            0.7
          )
          30px);
  border: 1px solid #c3baaa;
  border-color: rgba(195, 186, 170, 0.9);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: inset 0 1px rgba(255, 255, 255, 0.5), inset 0 0 5px #d8e071, 0 0 1px rgba(0, 0, 0, 0.1),
    0 2px rgba(0, 0, 0, 0.02);
  box-shadow: inset 0 1px rgba(255, 255, 255, 0.5), inset 0 0 5px #d8e071, 0 0 1px rgba(0, 0, 0, 0.1),
    0 2px rgba(0, 0, 0, 0.02);
}

.notepaper:before,
.notepaper:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
}

.notepaper:before {
  left: 28px;
  width: 2px;
  border: solid #efcfad;
  border-color: rgba(239, 207, 173, 0.9);
  border-width: 0 1px;
}

.notepaper:after {
  z-index: -1;
  left: 0;
  right: 0;
  background: rgba(242, 246, 193, 0.9);
  border: 1px solid rgba(170, 157, 134, 0.7);
  -webkit-transform: rotate(2deg);
  -moz-transform: rotate(2deg);
  -ms-transform: rotate(2deg);
  -o-transform: rotate(2deg);
  transform: rotate(2deg);
}

.quote {
  font-family: Georgia, serif;
  font-size: 14px;
}

.curly-quotes:before,
.curly-quotes:after {
  display: inline-block;
  vertical-align: top;
  height: 30px;
  line-height: 48px;
  font-size: 50px;
  opacity: 0.2;
}

.curly-quotes:before {
  content: '\201C';
  margin-right: 4px;
  margin-left: -8px;
}

.curly-quotes:after {
  content: '\201D';
  margin-left: 4px;
  margin-right: -8px;
}

.quote-by {
  display: block;
  padding-right: 10px;
  text-align: right;
  font-size: 13px;
  font-style: italic;
  color: #84775c;
}

.lt-ie8 .notepaper {
  padding: 15px 25px;
}
.height__630 {
  .quote-by {
    font-size: 1.4em;
  }
}
.height__1080,
.height__1920 {
  .quote-by {
    font-size: 2em;
  }
}
</style>
