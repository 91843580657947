import { render, staticRenderFns } from "./TextColorPanel.vue?vue&type=template&id=25e77875&scoped=true&"
import script from "./TextColorPanel.vue?vue&type=script&lang=js&"
export * from "./TextColorPanel.vue?vue&type=script&lang=js&"
import style0 from "./TextColorPanel.vue?vue&type=style&index=0&id=25e77875&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25e77875",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VColorPicker } from 'vuetify/lib/components/VColorPicker';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VColorPicker,VIcon})
