<template>
  <v-app>
    <div class="app__main">
      <div class="w-full h-full d-flex ">
        <v-col
          cols="8"
        >
          <div class="app__top d-flex align-center">
            <img
              class="mr-3"
              width="35"
              height="35"
              :src="require('@/assets/images/svg/quote-icon.svg')"
            />
            <h2>
              QouteShot
            </h2>
          </div>

          <v-row>
            <v-col
              :cols="exportPreview ? '12' : '12'"
              :style="exportPreview ? `max-width:${card.selectedSize.width}px;min-width:${card.selectedSize.width}px;padding:0` : 'width:auto'"
            >
              <div class="main__container ">
                <div class="size__control">
                  <div
                    ref="tweetExportRef"
                    :class="`app__wrapper d-flex  ${exportPreview ? '' : 'transform__' + card.selectedSize.height} height__${card.selectedSize.height} ${activeFont}`"
                    :style="`${ activeBackground };width:${card.selectedSize.width}px;height:${card.selectedSize.height}px`"
                  >
                    <component
                      :is="resolveTheme"
                      ref="theme"
                      :quote="quote"
                      :card="card"
                      :fit-content="fitContent"
                    ></component>
                  </div>
                </div>

                <div class="d-flex justify-space-between align-baseline my-2 ">
                  <div class="font-weight-bold mx-3">
                    Usage: {{ limits.usedImages }}/{{ limits.maxImages }}
                  </div>
                  <div
                    class="text-center bg-primary"
                  >
                    <v-btn
                      class="mx-3"
                      color="primary"
                      x-large
                      @click="copyToClipBoard"
                    >
                      <v-icon class="mr-2 pointer">
                        {{ icons.mdiImage }}
                      </v-icon>
                      <label class="pointer">Copy</label>
                    </v-btn>

                    <v-btn
                      color="primary"
                      x-large
                      @click="exportImage"
                    >
                      <v-icon class="mr-2 pointer">
                        {{ icons.mdiDownload }}
                      </v-icon>
                      <label class="pointer">Download</label>
                    </v-btn>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-col>

        <v-col
          cols="4"
        >
          <sidebar
            :icons="icons"
            :switch-theme="switchTheme"
            :toggle-card-theme="toggleCardTheme"
            :card.sync="card"
            :sizes="sizes"
            :select-size="selectSize"
          />
        </v-col>
      </div>

      <!-- additional modules -->

      <!-- dialog for loading -->
      <!-- full page loading -->
      <div
        v-if="exportPreview"
        class="loading"
      >
        <img :src="loadingSvg">
      </div>
      <!-- short loading -->
      <v-dialog
        v-model="loading"
        hide-overlay
        persistent
        width="300"
      >
        <v-card
          color="primary"
          dark
        >
          <v-card-text class="pt-3">
            Please stand by
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- snackbar for message -->
      <v-snackbar
        v-model="snackbar.show"
        :timeout="2000"
        :light="$vuetify.theme.dark"
      >
        {{ snackbar.text }}
      </v-snackbar>
    </div>
  </v-app>
</template>

<script>
import DefaultTheme from './components/quotes/DefaultTheme.vue'
import PaperTheme from './components/quotes/PaperTheme.vue'

import Sidebar from './components/quotes/sidebar/Index.vue'

// mixins
import ControlMixin from './mixins/Control'

export default {
  metaInfo: {
    title: 'Quotes',
  },
  components: {
    DefaultTheme,
    PaperTheme,
    Sidebar,
  },
  mixins: [ControlMixin],
  data() {
    return {
      page: 'quotes',

      quote: {
        theme: 'default-theme',
        text: 'Write your quotes here!',
        user: {
          name: 'Who says?',
          image: null,
        },
      },
    }
  },
  computed: {
    resolveTheme() {
      if (this.quote.theme) return this.quote.theme

      return 'default-theme'
    },
  },
  watch: {
    quote: {
      handler() {
        this.setLog(true)
      },
      deep: true,
    },
  },
  created() {
    this.card.theme = 'dark'
  },
  mounted() {
    this.fitContent()
  },
  methods: {
    switchTheme(theme) {
      this.themeSelectionEnable = false
      this.quote.theme = theme.id
      this.setLog(false)
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/styles/tweet-task.scss';
.app__form {
  max-width: 600px;
  padding: 2rem;
  margin: auto;
}

.editor {
  color: black !important;
  .body {
    width: 200px;
  }
}
</style>
