<template>
  <v-card
    class="theme__card"
  >
    <div
      :class="'quote__theme d-flex flex-column justify-center tweet__card__' + card.theme "
    >
      <div class="hide__overflow">
        <div
          ref="content"
          class="content "
          contenteditable
          :style="styles"
          @keyup="fitContent"
        >
          {{ quote.text }}
        </div>
      </div>
      <div>
        <span
          v-if="quote.user.name"
          class="quote__user"
          contenteditable
        >
          {{ quote.user.name }}
        </span>
      </div>
    </div>
  </v-card>
</template>

<script>
import QuoteTheme from '@/views/social/mixins/QuoteTheme'

export default {
  mixins: [QuoteTheme],

  props: {
    fitContent: {
      required: true,
      type: Function,
    },
    quote: {
      type: Object,
      required: true,
    },
    card: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      heights: [
        {
          on: 630,
          allowed: 350,
          fontSize: 24,
          minFontSize: 12,
        },
        {
          on: 1080,
          allowed: 680,
          fontSize: 24,
          minFontSize: 14,
        },
        {
          on: 1920,
          allowed: 1420,
          fontSize: 36,
          minFontSize: 14,
        },
      ],
    }
  },
}
</script>
<style scoped lang="scss">
.tweet__card__light {
  background: white;
  .content {
    color: black;
    &:after {
      color: white;
    }
  }
}
.theme__card {
  max-width: 80%;
  width: 80%;
  height: 80%;
  margin: auto;
  margin-bottom: auto;
  padding: 1rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.quote__theme {
  width: 100%;
  height: 100%;
  padding: 20px;
  border: 1px solid #c60e45;
  padding: 1rem;
  text-align: center;

  .content {
    padding: 20px;
    text-align: center;
    margin-bottom: 1rem;
    &:after {
      content: '❝';
      position: absolute;
      z-index: 1;
      left: 50%;
      top: 34px;
      transform: translate(-50%, -50%);
      width: 1.3em;
      height: 1.3em;
      background: #c60e45;
      box-shadow: 0 4px 5px -1px hsl(0deg 0% 0% / 20%);
      border-radius: 999px;
      display: grid;
      place-content: center;
      padding-top: 0.5em;
      color: var(--accent-color);
      font-size: 36px;
      font-style: normal;
      text-indent: 0;
    }
  }

  .quote__user {
    background: #c60e45;
    text-transform: uppercase;
    padding: 3px 10px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    font-size: 10pt;
    color: white;
  }
}

.height__1080,
.height__1920 {
  .quote__user {
    font-size: 2em;
  }
}
</style>
