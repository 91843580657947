const QuoteTheme = {
  computed: {
    styles() {
      const { textColor } = this.$store.state.social

      return textColor ? `color:${textColor} !important` : ''
    },
  },
}

export default QuoteTheme
