const socialDB = {
  twitterThemes: [
    {
      title: 'Default',
      id: 'default-theme',
      thumb: require('@/assets/images/themes/theme-0.png'),
      actions: [],
      settings: {
        themeBackground: 'light',
      },
      colorScheme: {
        textPrimary: '#000',
        textSecondary: '#8d787d',
        link: '#1ea0f1',
        verifiedIcon: '#1ea0f1',
        cardBackgroundColor: '#fff',
        cardBackgroundImage: null,
      },
      darkColorScheme: {
        textPrimary: '#fff',
        textSecondary: '#CDCDCD',
        verifiedIcon: '#fff',
        cardBackgroundColor: '#fff',
        cardBackgroundImage: 'linear-gradient(-50deg, rgba(0, 0, 0, 0.94), rgba(0, 0, 0, 0.58) 100%)',
      },
    },
    {
      title: 'Theme 1',
      id: 'theme-1',
      thumb: require('@/assets/images/themes/theme-1.png'),
      actions: ['response', 'date'],
      settings: {
        themeBackground: 'dark',
      },
      colorScheme: {
        textPrimary: '#000',
        textSecondary: '#8d787d',
        verifiedIcon: '#1ea0f1',
        cardBackgroundColor: '#fff',
        cardBackgroundImage: null,
      },
      darkColorScheme: {
        textPrimary: '#fff',
        textSecondary: '#8d787d',
        verifiedIcon: '#fff',
        cardBackgroundColor: '#000',
        cardBackgroundImage: null,
      },
    },
    {
      title: 'Theme 2',
      id: 'theme-2',
      thumb: require('@/assets/images/themes/theme-2.png'),
      actions: ['card'],

      colorScheme: {
        backgroundColor: '#1B9DE2',
        textPrimary: '#000',
        verifiedIcon: '#1ea0f1',

      },
    },
    {
      title: 'Theme 3',
      id: 'theme-3',
      thumb: require('@/assets/images/themes/theme-3.png'),
      actions: ['response'],
      settings: {
        themeBackground: 'light',
      },
      colorScheme: {
        backgroundColor: '#3F3486',
        textPrimary: '#000',
        textSecondary: '#8d787d',
        verifiedIcon: '#1ea0f1',
        cardBackgroundColor: '#fff',
        cardBackgroundImage: null,
      },
      darkColorScheme: {
        textPrimary: '#fff',
        textSecondary: '#8d787d',
        verifiedIcon: '#1ea0f1',
        cardBackgroundColor: '#fff',
        cardBackgroundImage: 'linear-gradient(-50deg, rgba(0, 0, 0, 0.94), rgba(0, 0, 0, 0.58) 100%)',
      },
    },
    {
      title: 'Theme 4',
      id: 'theme-4',
      thumb: require('@/assets/images/themes/theme-4.png'),
      actions: ['response', 'card'],
      settings: {
        background: 'background-image:linear-gradient(150deg, rgb(255, 242, 158), rgb(255, 239, 153), rgb(255, 231, 140), rgb(255, 217, 121), rgb(255, 197, 98), rgb(255, 171, 75), rgb(255, 143, 52), rgb(255, 115, 33), rgb(255, 95, 20), rgb(255, 87, 15))',
      },

      colorScheme: {
        backgroundImage: 'background-image:linear-gradient(150deg, rgb(255, 242, 158), rgb(255, 239, 153), rgb(255, 231, 140), rgb(255, 217, 121), rgb(255, 197, 98), rgb(255, 171, 75), rgb(255, 143, 52), rgb(255, 115, 33), rgb(255, 95, 20), rgb(255, 87, 15))',
        textPrimary: '#000',
        textSecondary: '#8d787d',
        verifiedIcon: '#1ea0f1',
      },
    },
    {
      title: 'Theme 5',
      id: 'theme-5',
      thumb: require('@/assets/images/themes/theme-5.png'),
      actions: ['response', 'shadow', 'card'],
      settings: {
        background: 'background:linear-gradient(150deg, rgb(95, 108, 138), rgb(48, 59, 94), rgb(14, 18, 38));',
      },

      colorScheme: {
        textPrimary: '#FFF',
        textSecondary: '#8d787d',
        verifiedIcon: '#1ea0f1',
      },
    },
    {
      title: 'Theme 6',
      id: 'theme-6',
      thumb: require('@/assets/images/themes/theme-6.png'),
      actions: ['card', 'shadow', 'card'],

      colorScheme: {
        textPrimary: '#000',
        textSecondary: '#8d787d',
        verifiedIcon: '#1ea0f1',
      },
    },
    {
      title: 'Theme 7',
      id: 'theme-7',
      thumb: require('@/assets/images/themes/theme-7.png'),
      actions: ['card', 'shadow', 'card'],
      colorScheme: {
        textPrimary: '#000',
        textSecondary: '#8d787d',
        verifiedIcon: '#1ea0f1',
      },
    },
    {
      title: 'Theme 8',
      id: 'theme-8',
      thumb: require('@/assets/images/themes/theme-8.png'),
      actions: ['response'],
      settings: {
        themeBackground: 'light',
      },
      colorScheme: {
        backgroundColor: '#FFDB27',
        textPrimary: '#000',
        textSecondary: '#8d787d',
        verifiedIcon: '#1ea0f1',
        cardBackgroundColor: '#fff',
        cardBackgroundImage: null,
      },
      darkColorScheme: {
        textPrimary: '#fff',
        textSecondary: '#F0D9DF',
        verifiedIcon: '#C8EAFF',
        cardBackgroundColor: '#000',
        cardBackgroundImage: null,
      },
    },
    {
      title: 'Theme 9',
      id: 'theme-9',
      thumb: require('@/assets/images/themes/theme-10.png'),
      actions: ['response', 'card'],
      settings: {
        background: 'background:#c61044',
      },
      colorScheme: {
        backgroundColor: '#c61044',
        textPrimary: '#FFF',
        textSecondary: '#FFF',
        verifiedIcon: '#FFF',
      },

    },
  ],
  qouteThemes: [
    {
      title: 'Default Theme',
      id: 'default-theme',
      thumb: require('@/assets/images/themes/quote-theme-default.png'),
    },
    { title: 'Note Paper', id: 'paper-theme', thumb: require('@/assets/images/themes/quote-theme-1.png') },
  ],
}
export default socialDB
