import apiClient from '@/services'
import {
  mdiAlertCircleOutline,
  mdiCheckDecagram,
  mdiCircleOutline,
  mdiCircleSlice8,
  mdiClockOut,
  mdiClockOutline,
  mdiContentCopy,
  mdiDelete,
  mdiDeleteCircle,
  mdiDownload,
  mdiFormatQuoteOpen,
  mdiFormatSize,
  mdiHeart,
  mdiHeartOutline,
  mdiHistory,
  mdiImage,
  mdiMoonWaningCrescent,
  mdiPlus,
  mdiTwitter,
  mdiWhiteBalanceSunny
} from '@mdi/js'
import { toBlob, toPng } from 'html-to-image'
import { mapActions, mapState } from 'vuex'

const Control = {
  data() {
    return {
      error: null,

      // theme selection modal show/hide
      themeSelectionEnable: false,

      theme: 'default-theme',

      sizes: [
        {
          title: 'Facebook Post',
          width: 1200,
          height: 630,
          class: 'facebook__post',
        },
        {
          title: 'Instagram Post',
          width: 1080,
          height: 1080,
          class: 'insta__post',
        },
        {
          title: 'Instagram Story',
          width: 1080,
          height: 1920,
          class: 'insta__story',
        },
      ],

      card: {
        theme: 'light',
        response: true,

        showDate: true,
        selectedSize: { title: 'Facebook post ', width: 1200, height: 630 },
        shadow: true,
      },
      exportPreview: false,
      loadingSvg: require('@/assets/images/svg/loader.svg'),
      photoSvg: require('@/assets/images/svg/photo.svg'),

      snackbar: {
        show: false,
        text: null,
      },
      icons: {
        mdiTwitter,
        mdiImage,
        mdiCircleOutline,
        mdiCheckDecagram,
        mdiMoonWaningCrescent,
        mdiHeart,
        mdiCircleSlice8,
        mdiContentCopy,
        mdiDownload,
        mdiAlertCircleOutline,
        mdiWhiteBalanceSunny,
        mdiHeartOutline,
        mdiClockOutline,
        mdiClockOut,
        mdiPlus,
        mdiDeleteCircle,
        mdiDelete,
        mdiFormatSize,
        mdiHistory,
        mdiFormatQuoteOpen,
      },
    }
  },
  computed: {
    ...mapState({
      loading: state => state.social.loading,
      logEnabled: state => state.social.logEnabled,
      limits: state => state.social.limits,
      colorScheme: state => state.social.colorScheme,
      activeBackground: state => state.social.activeBackground,
      myBackgrounds: state => state.social.myBackgrounds,
      backgroundDialg: state => state.social.backgroundDialg,
      defaultBackgrounds: state => state.social.defaultBackgrounds,
      fontsDialg: state => state.social.fontsDialg,
      activeFont: state => state.social.activeFont,
    }),

    resolveContainerBackground() {
      if (this.colorScheme.backgroundImage) return this.colorScheme.backgroundImage

      return `background:${this.colorScheme.backgroundColor}`
    },
  },
  created() {
    this.fetchUsageLimits()
    if (this.myBackgrounds.length === 0) this.getBackgrounds()
    this.setLog(true)

    // console.log(window.devicePixelRatio)

    // this.$store.state.appConfig.app.contentWidth = 'full'
  },
  methods: {
    ...mapActions('social', [
      'backgroundDialgToggle',
      'loadingToggle',
      'setLog',
      'getBackgrounds',
      'fetchUsageLimits',
      'fontsDialogToggle',
      'setTextColor',
      'applyDefaultBackground',
      'setColorScheme',
    ]),
    ...mapActions('tracking', ['logActivity']),

    kFormatter(num) {
      return Math.abs(num) > 999
        ? `${Math.sign(num) * (Math.abs(num) / 1000).toFixed(1)}k`
        : Math.sign(num) * Math.abs(num)
    },
    toggleCardTheme() {
      const theme = this.themes.find(item => item.id === this.theme)
      this.card.theme = this.card.theme === 'light' ? 'dark' : 'light'
      if (this.card.theme === 'dark' && theme.darkColorScheme) {
        this.setColorSchemeWrapper(theme.darkColorScheme)
      } else this.setColorSchemeWrapper(theme.colorScheme)
    },

    selectSize(size) {
      this.setLog(true)
      this.error = null
      if (this.page === 'twitter-images' && size.height <= 1080 && this.tweet.media.length) {
        this.error = 'Images are only supported in story format'
      }
      this.card.selectedSize = size
      setTimeout(() => {
        this.fitContent()
      }, 500)
    },
    notify(text) {
      this.snackbar.show = true
      this.snackbar.text = text
    },
    exportImage() {
      if (this.checkLimit()) {
        // log tracking active
        this.logExportActivity('Download')

        const el = this.$refs.tweetExportRef
        this.exportPreview = true
        toPng(el, {
          width: this.card.selectedSize.width,
          height: this.card.selectedSize.height,
        })
          .then(dataUrl => {
            const link = document.createElement('a')
            link.download = 'twitter-post.png'
            link.href = dataUrl
            link.click()
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => {
            this.exportPreview = false
          })
      }
    },
    copyToClipBoard() {
      if (this.checkLimit()) {
        // log tracking active
        this.logExportActivity('Copy')

        const el = this.$refs.tweetExportRef

        this.exportPreview = true

        toBlob(el, {
          skipAutoScale: true,
          width: this.card.selectedSize.width,
          height: this.card.selectedSize.height,
        }).then(blob => {
          navigator.clipboard.write([new ClipboardItem({ 'image/png': blob })])

          this.notify('Copied to clipboard')
        })
          .finally(() => {
            this.exportPreview = false
          })
      }
    },

    fitContent() {
      const ref = this.page === 'twitter-images' ? this.$refs.theme.$refs.tweetContent : this.$refs.theme.$refs.content
      const { allowed, fontSize, minFontSize } = this.$refs.theme.heights.find(
        item => item.on === this.card.selectedSize.height,
      )
      ref.style.fontSize = `${fontSize}pt`
      ref.style.maxHeight = '100%'

      const actualHeight = ref.offsetHeight

      // console.log(`height allowed:${allowed} : actual ${actualHeight}`)

      if (actualHeight > allowed) {
        for (let font = fontSize; font >= minFontSize; --font) {
          ref.style.fontSize = `${font}pt`
          if (ref.offsetHeight <= allowed) {
            // console.log(`stop it ${ref.offsetHeight}`)
            return
          }
        }
        ref.style.fontSize = `${minFontSize}pt`
        ref.style.maxHeight = `${allowed}px`
      }
    },

    logExportActivity() {
      let extraProp = {}
      if (this.page === 'twitter-images') {
        extraProp = {
          tweet: { url: this.url, ...this.tweet },
        }
      } else {
        extraProp = {
          quote: this.quote,
        }
      }
      apiClient.toolActivity({
        tool: this.page === 'twitter-images' ? 'tweetshots' : 'quoteshots',
      }).catch(err => console.log(err))

      this.logActivity({
        activityName: this.page === 'twitter-images' ? 'TweetDownload' : 'QuoteDownload',
        properties: {
          theme: this.theme,
          card: this.card,
          activeFont: this.activeFont,
          activeBackground: this.activeBackground,
          ...extraProp,
        },
      })
    },

    isRetinaDisplay() {
      return (
        ((window.matchMedia
          && (window.matchMedia(
            'only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx), only screen and (min-resolution: 75.6dpcm)',
          ).matches
            || window.matchMedia(
              'only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min--moz-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2)',
            ).matches))
          || (window.devicePixelRatio && window.devicePixelRatio >= 2))
        && /(iPad|iPhone|iPod)/g.test(navigator.userAgent)
      )
    },
    checkLimit() {
      if (this.logEnabled === false) return true

      if (this.limits.leftImages > 0) {
        this.$store.dispatch('social/logLimit')
        this.setLog(false)

        return true
      }
      this.notify("You don't have any credit left")
      if (process.env.VUE_APP_DOMAIN === 'localhost') return true

      return false
    },
  },
}
export default Control
