<template>
  <v-list dense>
    <v-list-item-group
      color="primary"
    >
      <v-list-item
        v-for="(fontClass, i) in fonts"
        :key="i"
        elevation="2"
      >
        <v-list-item-content>
          <v-list-item-title
            :class="`font-lg ${fontClass} `"
            @click="setActiveFont(fontClass)"
          >
            You can add your product as many fancy features
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      fontsDialg: state => state.social.fontsDialg,
      fonts: state => state.social.fonts,
    }),
  },
  methods: {
    ...mapActions('social', ['fontsDialogToggle', 'setActiveFont']),
  },
}
</script>
<style scoped>
.font-lg {
  font-size: 18pt !important;
  padding: 0.6rem;
}
</style>
